import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Flex,
  Text,
  TextField,
  Transfer,
  Select,
} from "@wingmate/toolkit";
import { alphabeticallySort } from "../../../../utils/commons";

import "./GroupForm.scss";

export function GroupForm({ defaultGroup = {}, groups, onSubmit, users }) {
  const [group, setGroup] = useState(defaultGroup);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const userData = useMemo(
    () =>
      users.map((user) => ({
        key: user.id.toString(),
        title: user.attributes.fullName,
      })),
    [users]
  );

  const groupData = useMemo(
    () =>
      alphabeticallySort(groups, "name").map((availableGroup) => ({
        key: availableGroup.id.toString(),
        title: availableGroup.attributes.name,
      })),
    [groups]
  );

  const onFieldChange = (payload) => {
    setSuccessMessage();
    const newGroup = { ...group };
    newGroup[payload.id] = payload.value;

    setGroup(newGroup);
  };

  const submitForm = async () => {
    setSuccessMessage();
    try {
      await onSubmit(group);

      setSuccessMessage("Group saved.");
    } catch (err) {
      setErrorMessage(err.response.data);
    }
  };

  const onGroupMembersChange = (nextTargetKeys) => {
    setSuccessMessage();
    const newGroup = { ...group };

    newGroup.groupIds = nextTargetKeys;

    setGroup(newGroup);
  };

  const onUserMembersChange = (nextTargetKeys) => {
    setSuccessMessage();
    const newGroup = { ...group };

    newGroup.userIds = nextTargetKeys;

    setGroup(newGroup);
  };

  const createdByUserId = group.createdById?.toString();

  const userSelectOptions = users.map((user) => ({
    label: user.attributes.fullName,
    value: user.id,
  }));

  return (
    <Flex className="GroupForm" gap={12} vertical>
      {errorMessage && (
        <Text type="H3" className="GroupForm__Error">
          {errorMessage}
        </Text>
      )}
      {successMessage && (
        <Text type="H3" className="GroupForm__Success">
          {successMessage}
        </Text>
      )}
      <TextField
        id="name"
        defaultValue={group.name}
        label="Name"
        onChange={onFieldChange}
      />
      <TextField
        id="tag"
        defaultValue={group.tag}
        label="Tag"
        onChange={onFieldChange}
      />
      <div className="GroupForm__CreatedBySelect">
        <Select
          id="createdById"
          defaultValue={createdByUserId}
          label="Created By"
          options={userSelectOptions}
          onChange={onFieldChange}
        />
      </div>
      <Flex align="center" justify="space-between">
        <div>
          <Text type="H6">Users in Group</Text>
          <Transfer
            data={userData}
            titles={["Excluded", "In Group"]}
            onChange={onUserMembersChange}
            defaultTargetKeys={group.userIds}
          />
        </div>
        <div>
          <Text type="H6">Sub-groups</Text>
          <Transfer
            data={groupData}
            titles={["Excluded", "In Group"]}
            onChange={onGroupMembersChange}
            defaultTargetKeys={group.groupIds}
          />
        </div>
      </Flex>
      <Button type="filled" primary onClick={submitForm}>
        Submit
      </Button>
    </Flex>
  );
}

GroupForm.propTypes = {
  defaultGroup: PropTypes.shape({
    name: PropTypes.string,
    userIds: PropTypes.arrayOf(PropTypes.string),
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  onSubmit: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        fullName: PropTypes.string,
      }),
    })
  ),
};

export default GroupForm;
