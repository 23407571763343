import React from "react";
import PropTypes from "prop-types";
import { GroupForm } from "../GroupForm/GroupForm";
import { putGroup } from "../../../../requests/groups";

export function EditGroupForm({ group, groups, users }) {
  const submitForm = async (groupPayload) => {
    await putGroup(group.id, groupPayload);
  };

  return (
    <div>
      <GroupForm
        defaultGroup={{
          name: group.attributes.name,
          userIds: group.attributes.userIds.map((userId) => userId.toString()),
          groupIds: group.attributes.groupIds.map((groupId) =>
            groupId.toString()
          ),
          tag: group.attributes.tag,
          createdById: group.attributes.createdById,
        }}
        groups={groups}
        users={users}
        onSubmit={submitForm}
      />
    </div>
  );
}

EditGroupForm.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      tag: PropTypes.string,
      userIds: PropTypes.arrayOf(PropTypes.number),
      groupIds: PropTypes.arrayOf(PropTypes.number),
      createdById: PropTypes.number,
    }),
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        fullName: PropTypes.string,
      }),
    })
  ),
};

export default EditGroupForm;
