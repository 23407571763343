import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./Spinner.scss";

export class Spinner extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;

    const SpinnerClass = classNames("Spinner", className);

    return (
      <div className={SpinnerClass} data-testid="Spinner">
        <span />
        <span />
      </div>
    );
  }
}

export default Spinner;
