import * as axiosService from "./axiosRequests";

const GROUP_ENDPOINT = (groupId) => `/admin/groups/${groupId}.json`;

export async function putGroup(groupId, groupPayload) {
  const { createdById, groupIds, name, tag, userIds } = groupPayload;

  const response = await axiosService.axiosPut(GROUP_ENDPOINT(groupId), {
    group: {
      name,
      tag,
      user_ids: userIds,
      group_ids: groupIds,
      created_by_id: createdById,
    },
  });

  return response.data;
}

export default {
  putGroup,
};
